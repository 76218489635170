import { useState } from "react";
import { Header } from "./components/header";
import { Jumbotron } from "./components/jumbotron";
import { AboutUs } from "./components/about-us";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Whatsapp from "./components/whatsapp";
import { Artifacts } from "./components/artifacts";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  header: "[data-scroll-header]",
});

const App = () => {
  const defaultPage = "about";
  const [section, setSection] = useState(defaultPage);
  const [href, setHref] = useState();

  const handleChangeSection = () => {
    switch (section) {
      case "about":
        return (
          <>
            <Jumbotron />
            <AboutUs />
          </>
        );
      case "projects":
        return <Services href={href} setHref={setHref} />;
      case "artifacts":
        return <Artifacts />;
      default:
        return <h1>No project match</h1>;
    }
  };

  return (
    <div>
      <Header setSection={setSection} setHref={setHref} />
      <>{handleChangeSection()}</>
      <Whatsapp />
      <Contact />
    </div>
  );
};

export default App;
