export const TextImageRow = ({ id, img, title, text, isLeft }) => {
  return (
    <div id={id}>
      {!!isLeft ? (
        <>
          <div className="about-section">
            <div className="row">
              <div
                className="col-lg-offset-2 col-xs-12 col-sm-12 col-md-6 col-lg-4 vcenter"
                style={{ padding: 0 }}
              >
                <h3>{title}</h3>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 vcenter"></div>
            </div>
            <div className="row">
              <div className="col-lg-offset-2 col-xs-12 col-sm-12 col-md-6 col-lg-4 vcenter">
                <article className="about-text">
                  <p>{text}</p>
                </article>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 text-image-row vcenter">
                <img src={img} className="img-responsive img-about" alt="" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="about-section">
            {/* LA VIDA DEL PROYECTO */}
            <div className="row" id={id}>
              <div className="col-lg-offset-2 col-sm-12 col-xs-12 col-md-6 col-lg-4 vcenter"></div>
              <div
                className="col-xs-12 col-sm-12 col-md-7 col-lg-4 vcenter"
                style={{ padding: 0 }}
              >
                <h3>{title}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-offset-2 col-sm-12 col-xs-12 col-md-6 col-lg-4 text-image-row vcenter">
                <img src={img} className="img-responsive img-about" alt="" />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 d-flex align-items-center vcenter">
                <article className="about-text ">
                  <p>{text}</p>
                </article>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
