import { useState } from "react";
import { TextImageRow } from "./text-image-row";
import { ServiceNavImage } from "./service-nav-image";

export const Services = ({ href, setHref }) => {
  const [img, setImg] = useState("img/services/bim1.svg");

  const handleChangeSection = () => {
    const id = href?.split("#")[1];
    switch (id) {
      case "projects-bim":
        return (
          <TextImageRow
            id={"projects-bim"}
            isLeft={true}
            title={"OUTSOURCING BIM ARCHITECTURE"}
            text={`Modelado BIM de proyectos de arquitectura (maquetado BIM),
       estructuras e infraestructura (BIM), modelado de Anteproyectos,
       computos, planillas de computos y aberturas, planos de base, ,
       renderizados. Trabajo en colaboración de una parte del proyecto
       o modelado 100%.`}
            img={"img/services/bim2.svg"}
          ></TextImageRow>
        );
      case "projects-cad":
        return (
          <TextImageRow
            id={"projects-cad"}
            isLeft={false}
            title={"OUTSOURCING AUTOCAD"}
            text={`Anteproyectos de arquitectura, Asistencia en todas
         las etapas del proyecto y revisiones posteriores.
         Desarrollos de Legajos Técnicos de Obra.`}
            img={"img/services/cad2.svg"}
          ></TextImageRow>
        );
      case "projects-mep":
        return (
          <TextImageRow
            id={"projects-mep"}
            isLeft={true}
            title={`OUTSOURCING MEP`}
            text={`Nuestro equipo cuenta con expertos modeladores MEP(Mechanical,
           Electrical and Plumbing). Realizamos modelos BIM en la disciplina de
           instalaciones. Es por eso que podemos generar modelos BIM complejos a
           partir de esquemas en 2D o planos CAD 2D.`}
            img={"img/services/mep2.svg"}
          ></TextImageRow>
        );
      case "projects-arq":
        return (
          <TextImageRow
            id={"projects-arq"}
            isLeft={false}
            title={"PROYECTOS DE ARQUITECTURA"}
            text={`Una larga trayectoria en proyectos de mediana y gran envergadura nos avalan. Desarrollamos anteproyectos, proyectos, desarrollo de legajos técnicos, planos municipales y gestiones de factibilidades. Empleando tecnologías BIM en todas sus etapas.`}
            img={"img/services/arq2.svg"}
          ></TextImageRow>
        );
      default:
        break;
    }
  };
  return (
    <div id="services" className="text-center">
      <div
        style={{
          margin: "0 0 0 0",
          width: "100%",
          marginBottom: "10rem",
        }}
      >
        <div className="section-title">
          <h2>Nuestros servicios</h2>
        </div>
        <div
          src={img}
          className="row service-full-image"
          style={{
            background: `url(${img}) no-repeat center center / cover`,
          }}
        >
          <ServiceNavImage
            setImg={setImg}
            setHref={setHref}
            title={"OUTSOURCING BIM ARCHITECTURE"}
            href={"#projects-bim"}
            img={"img/services/bim1.svg"}
          />
          <ServiceNavImage
            setImg={setImg}
            setHref={setHref}
            title={"OUTSOURCING AUTOCAD"}
            href={"#projects-cad"}
            img={"img/services/cad1.svg"}
          />
          <ServiceNavImage
            setImg={setImg}
            setHref={setHref}
            title={`OUTSOURCING\n MEP`}
            href={"#projects-mep"}
            img={"img/services/mep1.svg"}
          />
          <ServiceNavImage
            setImg={setImg}
            setHref={setHref}
            title={"PROYECTOS DE ARQUITECTURA"}
            href={"#projects-arq"}
            img={"img/services/arq1.svg"}
          />
        </div>
      </div>
      {handleChangeSection()}
    </div>
  );
};
