import React from "react";
import FloatingWhatsApp from "react-floating-whatsapp";

export default function Whatsapp() {
  return (
    <FloatingWhatsApp
      styles={{ width: "40px", height: "40px" }}
      phoneNumber="5493513428262"
      accountName="EnkyDesign"
      statusMessage=""
      chatMessage="¡Hola! ¿Cómo podemos ayudarte?"
      allowClickAway={true}
      avatar="img/favicon.png"
      allowEsc={true}
    />
  );
}
