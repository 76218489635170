export const ServiceNavImage = ({ title, setImg, img, href, setHref }) => {
  return (
    <div
      className="col-xs-12 col-sm-6 col-md-3 col-lg-3 service-nav-image"
      onMouseOver={(e) => {
        setImg(img);
      }}
      onClick={(e) => setHref(href)}
    >
      <a
        className="service-image text-center "
        href={href}
        style={{ whiteSpace: "pre-line" }}
      >
        {title}
      </a>
    </div>
  );
};
