import Typed from "react-typed";

export const Jumbotron = () => {
  return (
      <div id="jumbotron" className="intro">
        <div className="overlay">
          <div className="col-md-offset-7 col-lg-offset-7 col-md-5 col-lg-5 intro-text">
            <h1>Somos un estudio de ARQUITECTURA y DISEÑO. </h1>
            <Typed
              strings={[
                "Brindando soporte a estudios de arquitectura e ingeniería.",
                "Trabajando de forma remota permitiendo a los mismos.",
                "Absorber las diferentes realidades de los proyectos y su evolución.",
              ]}
              typeSpeed={150}
              backSpeed={100}
              loop
            />
          </div>
        </div>
      </div>
  );
};
