import { useState } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message, e.target);
    emailjs
      .sendForm(
        "service_zmb89q7",
        "template_odm1jza",
        e.target,
        "Hktb3NVRAVQWrWr21"
      )
      .then(
        (result) => {
          clearState();
          alert(
            "Su mensaje ha sido enviado correctamente. ¡Gracias por su contacto!"
          );
        },
        (error) => {
          console.log(error.text);
          alert("No hemos podido entregar su mensaje.");
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-5">
            <div className="row">
              <div className="section-title">
                <h2>Contacto</h2>
                <p>
                  Por favor completa el formulario debajo para enviarnos un
                  correo, estaremos en contacto tan pronto podamos.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensaje
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6 col-md-offset-1 contact-info text-center">
            <h3>Información de contacto</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-map-marker"></i> Domicilio
                    </span>
                    Augusto Cortazar 4656, Córdoba, Argentina
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-envelope-o"></i> Email
                    </span>{" "}
                    info@enkidesign.io
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-phone"></i> Teléfono Argentina
                    </span>{" "}
                    +54 351 3428262
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-item">
                  <p>
                    <span>
                      <i className="fa fa-phone"></i> Teléfono España
                    </span>{" "}
                    +34 607380329
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
