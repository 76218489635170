export const Header = ({ setSection, setHref }) => {
  return (
    <nav id="menu" className="navbar-default navbar-fixed-top" data-scroll-header>
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            href="#"
            className="logo navbar-brand page-scroll"
            onClick={() => setSection("about")}
          ></a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav dropdown-toggle">
            <li className="dropdown">
              <a
                className="dropdown-toggle"
                onClick={() => setSection("about")}
                href="#about"
                type="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Nosotros
              </a>
              <ul className="dropdown-menu nav navbar-nav navbar-left">
                <li>
                  <a
                    href="#about-the-place"
                    onClick={() => {
                      setSection("about");
                      setHref("#about-the-place");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    El estudio
                  </a>
                </li>
                <li>
                  <a
                    href="#about-lifecycle"
                    onClick={() => {
                      setSection("about");
                      setHref("#about-lifecycle");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    La vida del proyecto
                  </a>
                </li>
                <li>
                  <a
                    href="#about-follow-up"
                    onClick={() => {
                      setSection("about");
                      setHref("#about-follow-up");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    Seguimiento del proyecto
                  </a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a
                onClick={() => setSection("projects")}
                className="dropdown-toggle"
                href="#services"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Nuestros Servicios
              </a>
              <ul className="dropdown-menu nav navbar-nav navbar-left">
                <li>
                  <a
                    href="#projects-bim"
                    onClick={() => {
                      setSection("projects");
                      setHref("#projects-bim");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    Outsourcing BIM
                  </a>
                </li>
                <li>
                  <a
                    href="#projects-cad"
                    onClick={() => {
                      setSection("projects");
                      setHref("#projects-cad");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    Outsourcing autocad
                  </a>
                </li>
                <li>
                  <a
                    href="#projects-mep"
                    onClick={() => {
                      setSection("projects");
                      setHref("#projects-mep");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    Outsorucing MEP
                  </a>
                </li>
                <li>
                  <a
                    href="#projects-arq"
                    onClick={() => {
                      setSection("projects");
                      setHref("#projects-arq");
                    }}
                    className="page-scroll dropdown-nav "
                  >
                    Proyectos de arquitectura
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#contact"
                onClick={() => setSection("about")}
                className="page-scroll"
              >
                Contacto
              </a>
            </li>
            <li>
              <a
                href="#artifacts"
                onClick={() => setSection("artifacts")}
                className="page-scroll"
              >
                Nuestros trabajos
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li className="rrss-nav">
              <a className="nav-icon rrss-nav" href="#contact">
                <i className="fa fa-envelope"></i>
              </a>
            </li>
            <li className="rrss-nav">
              <a
                className="nav-icon rrss-nav"
                href="https://www.linkedin.com/company/enkidesign/"
                target="_blank"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
