import { TextImageRow } from "./text-image-row";

export const AboutUs = () => {
  return (
    <div id="about">
      <div className="section-title text-center">
        <h2>Sobre Nosotros</h2>
      </div>
      {/* EL ESTUDIO */}
      <TextImageRow
        id={"about-the-place"}
        isLeft={true}
        title={"EL ESTUDIO"}
        text={`Nos dedicamos al acompañamiento en Proyectos de diferentes
                  escalas. Colaborando con diversos Estudios y Empresas en el
                  desarrollo ejecutivo de sus proyectos. Nuestro lema
                  "adaptarnos" a la necesidad de cada estudio, entendiendo que
                  el trabajo en equipo en las diferentes etapas del proyecto es
                  un "ida y vuelta" de retro alimentación procesal . Potenciando
                  esta interacción con las tecnologías BIM aplicadas al diseño y
                  a la resolución de proyectos.`}
        img={"img/about/place.svg"}
      ></TextImageRow>

      {/* LA VIDA DEL PROYECTO */}
      <TextImageRow
        id={"about-lifecycle"}
        isLeft={false}
        title={"LA VIDA DEL PROYECTO"}
        text={`El acompañamiento del desarrollo de los proyectos, comienza
          con el ANTEPROYECTO, así lo entendemos y trabajamos,
          adaptándonos a las diferentes escalas y situaciones,
          estudiando alternativas exploratorias según cada caso lo
          requiera. El desarrollo del maquetado BIM nos adentra en el
          PROYECTO de una manera resolutiva en cuanto a respuestas
          proyectuales, tecnológicas y económicas, generando el
          desarrollo de un legajo técnico detallado, que facilitara el
          desempeño de una OBRA planificada y ordenada.`}
        img={"img/about/lifecycle.svg"}
      ></TextImageRow>

      {/* SEGUIMIENTO */}
      <TextImageRow
        id={"about-follow-up"}
        isLeft={true}
        title={"SEGUIMIENTO"}
        text={`Colaboración en resolución de proyecto ejecutivo, aplicando
          metodología BIM (Autodesk Revit). Desarrollo de legajo técnico
          completo apto para obra, Renders, Cómputos y Presupuestos,
          aportando valor agregado y perfecta coordinación de todas las
          especialidades que intervienen en cualquier obra de
          Arquitectura. Evitando de esta manera desinformación y
          des-coordinación en obra, logrando asi, ahorro de problemas
          constructivos y económicos.`}
        img={"img/about/tracking.svg"}
      ></TextImageRow>
    </div>
  );
};
